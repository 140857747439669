import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Transition as _Transition, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "w-full mx-auto px-2 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "relative flex items-center justify-between h-16" }
const _hoisted_3 = { class: "absolute inset-y-0 left-0 flex items-center sm:hidden" }
const _hoisted_4 = { class: "flex-1 flex items-center justify-center sm:items-stretch sm:justify-start" }
const _hoisted_5 = { class: "hidden sm:block sm:ml-6 sm:px-5" }
const _hoisted_6 = { class: "flex space-x-4" }
const _hoisted_7 = ["aria-current", "onClick"]
const _hoisted_8 = { class: "flex flex-col-reverse sm:inset-auto sm:ml-6 sm:pr-0 sm:flex-row" }
const _hoisted_9 = {
  key: 0,
  class: "balance-container flex flex-col items-end sm:flex-row sm:items-center"
}
const _hoisted_10 = {
  key: 0,
  class: "text-gray-300 hidden sm:inline"
}
const _hoisted_11 = { class: "text-white font-medium text-[11px] sm:text-xs sm:px-2" }
const _hoisted_12 = {
  key: 1,
  class: "guest-content hidden sm:block"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "px-2 pt-2 pb-3 space-y-1" }
const _hoisted_15 = { class: "guest-content hidden: sm mb-5 pb-3 border-b" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_DisclosureButton = _resolveComponent("DisclosureButton")!
  const _component_language_dropdown = _resolveComponent("language-dropdown")!
  const _component_UserIcon = _resolveComponent("UserIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_DisclosurePanel = _resolveComponent("DisclosurePanel")!
  const _component_Disclosure = _resolveComponent("Disclosure")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createVNode(_component_Disclosure, {
      as: "nav",
      class: "bg-gray-800"
    }, {
      default: _withCtx(({ open }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_DisclosureButton, { class: "inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" }, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "sr-only" }, "Open main menu", -1)),
                  (!open)
                    ? (_openBlock(), _createBlock(_component_MenuIcon, {
                        key: 0,
                        class: "block h-6 w-6",
                        "aria-hidden": "true"
                      }))
                    : (_openBlock(), _createBlock(_component_XIcon, {
                        key: 1,
                        class: "block h-6 w-6",
                        "aria-hidden": "true"
                      }))
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex-shrink-0 flex items-center text-white font-medium" }, " IFRAME DEMO ", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
                    return (_openBlock(), _createElementBlock("button", {
                      key: item.key,
                      class: _normalizeClass([_ctx.currentNavItem.includes(item.rootPath) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']),
                      "aria-current": item.key === _ctx.currentNavItem ? 'page' : undefined,
                      onClick: ($event: any) => (_ctx.submitNavigationItem(item))
                    }, _toDisplayString(_ctx.$t(`header.${item.key}`)), 11, _hoisted_7))
                  }), 128))
                ])
              ])
            ]),
            _createVNode(_component_language_dropdown),
            _createElementVNode("div", _hoisted_8, [
              (_ctx.isLogged)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_ctx.userBalance)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("header.balance")), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.userBalance), 1)
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.isLogged)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_ctx.loginEnabled)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "text-white px-3 py-2 rounded-md text-sm font-medium mx-2 hover:bg-gray-700 hover:rounded-md",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openLoginModal && _ctx.openLoginModal(...args)))
                        }, _toDisplayString(_ctx.$t("header.login")), 1))
                      : _createCommentVNode("", true),
                    (_ctx.registerEnabled)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "text-white px-3 py-2 rounded-md text-sm font-medium mx-2 hover:bg-gray-700 hover:rounded-md",
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openRegisterModal && _ctx.openRegisterModal(...args)))
                        }, _toDisplayString(_ctx.$t("header.register")), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_Menu, {
                as: "div",
                class: "ml-3"
              }, {
                default: _withCtx(() => [
                  (_ctx.isLogged)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(_component_MenuButton, { class: "bg-gray-800 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ml-12 sm:ml-0" }, {
                          default: _withCtx(() => [
                            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "sr-only" }, "Open user menu", -1)),
                            _createVNode(_component_UserIcon, {
                              class: "h-5 w-5 sm:h-6 sm:w-6",
                              "aria-hidden": "true"
                            })
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_Transition, {
                    "enter-active-class": "transition ease-out duration-100",
                    "enter-from-class": "transform opacity-0 scale-95",
                    "enter-to-class": "transform opacity-100 scale-100",
                    "leave-active-class": "transition ease-in duration-75",
                    "leave-from-class": "transform opacity-100 scale-100",
                    "leave-to-class": "transform opacity-0 scale-95"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isLogged)
                        ? (_openBlock(), _createBlock(_component_MenuItems, {
                            key: 0,
                            class: "origin-top-right absolute right-0 mt-4 w-42 p-2 font-medium hover:bg-gray-200 hover:rounded-md rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_MenuItem, null, {
                                default: _withCtx(({ active }) => [
                                  _createElementVNode("a", {
                                    class: _normalizeClass(["cursor-pointer", [active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']]),
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitNavigationItem({ key: 'live-sports', path: `/my/betlist`})))
                                  }, _toDisplayString(_ctx.$t("profile_dropdown.my_bets")), 3)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_MenuItem, null, {
                                default: _withCtx(({ active }) => [
                                  _createElementVNode("a", {
                                    class: _normalizeClass(["cursor-pointer", [active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']]),
                                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openMySportsbookModal()))
                                  }, _toDisplayString(_ctx.$t("profile_dropdown.my_sportsbook")), 3)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_MenuItem, null, {
                                default: _withCtx(({ active }) => [
                                  _createElementVNode("a", {
                                    class: _normalizeClass(["cursor-pointer", [active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']]),
                                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onLogout && _ctx.onLogout(...args)))
                                  }, _toDisplayString(_ctx.$t("profile_dropdown.log_out")), 3)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createVNode(_component_DisclosurePanel, { class: "sm:hidden" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                (!_ctx.isLogged && _ctx.loginEnabled)
                  ? (_openBlock(), _createBlock(_component_DisclosureButton, {
                      key: 0,
                      class: "block px-3 py-2 rounded-md text-base font-medium text-white",
                      onClick: _ctx.openLoginModal
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("header.login")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.isLogged && _ctx.registerEnabled)
                  ? (_openBlock(), _createBlock(_component_DisclosureButton, {
                      key: 1,
                      class: "block px-3 py-2 rounded-md text-base font-medium text-white",
                      onClick: _ctx.openRegisterModal
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("header.register")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
                return (_openBlock(), _createBlock(_component_DisclosureButton, {
                  key: item.key,
                  as: "a",
                  class: _normalizeClass([item.key === _ctx.currentNavItem ? 'text-white' : 'text-gray-400 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']),
                  "aria-current": item.key === _ctx.currentNavItem ? 'page' : undefined,
                  onClick: ($event: any) => (_ctx.submitNavigationItem(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`header.${item.key}`)), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "aria-current", "onClick"]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}