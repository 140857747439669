const it = {
    header: {
        home: 'Casa',
        sports: 'Sport',
        live_betting: 'Scommesse dal vivo',
        today: 'Oggi',
        early: 'Precedente',
        live: 'Live',
        login: 'Accedi',
        register: 'Registrati Ora!',
        balance: 'Il saldo:',
    },
    profile_dropdown: {
        my_bets: 'Le mie scommesse',
        my_sportsbook: 'Il mio bookmaker',
        log_out: 'Disconnettersi',
    },
    login: {
        title: 'Accedi al tuo account',
        username: 'Nome utente',
        password: 'Parola d\'ordine',
        sign_in: 'Registrazione',
        error: {
            wrong_signatures: 'Combinazione nome utente e password sbagliata!',
            something_went_wrong: 'Qualcosa è andato storto. Per favore riprova più tardi.',
        },
        remember_me: 'Ricordati di me',
        forgot_password: 'Hai dimenticato la password?',
    },
    register: {
        title: 'Registra un account',
        email: 'E-mail',
        username: 'Nome utente',
        first_name: 'Nome di battesimo',
        last_name: 'Cognome',
        password: 'Parola d\'ordine',
        sign_up: 'Iscrizione',
        select_currency: 'Seleziona valuta',
        select_language: 'Seleziona lingua',
        select_odds_format: 'Seleziona formato di quota',
    },
    footer: {
        all_rights_reserved: 'Tutti i diritti riservati.',
    }
}
export default it
