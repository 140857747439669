import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-wrapper" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_main_footer = _resolveComponent("main-footer")!
  const _component_login_modal = _resolveComponent("login-modal")!
  const _component_register_modal = _resolveComponent("register-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_main_header, {
      onOpenLoginModal: _ctx.openLoginModal,
      onOpenRegisterModal: _ctx.openRegisterModal
    }, null, 8, ["onOpenLoginModal", "onOpenRegisterModal"]),
    _createElementVNode("div", {
      id: "main",
      class: _normalizeClass(["w-full", {'h-dynamic': _ctx.sportsbookDynamicHeight}])
    }, [
      _createElementVNode("iframe", {
        id: "sportsbookcontainer",
        src: _ctx.sportsBookUrl,
        "data-frame-id": "ds-sport-component",
        class: _normalizeClass(["w-full", [_ctx.sportsbookDynamicHeight ? 'h-dynamic' : 'h-full']]),
        onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onIframeLoad && _ctx.onIframeLoad(...args)))
      }, null, 42, _hoisted_2)
    ], 2),
    (_ctx.footerEnabled)
      ? (_openBlock(), _createBlock(_component_main_footer, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_login_modal, { showLoginModal: _ctx.showLoginModal }, null, 8, ["showLoginModal"]),
    _createVNode(_component_register_modal, { showRegisterModal: _ctx.showRegisterModal }, null, 8, ["showRegisterModal"])
  ]))
}