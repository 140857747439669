const en = {
    header: {
        home: 'Home',
        sports: 'Sports',
        live_betting: 'Live Betting',
        today: 'Today',
        early: 'Early',
        live: 'Live',
        login: 'Login',
        register: 'Register',
        balance: 'Balance:',
    },
    profile_dropdown: {
        my_bets: 'My Bets',
        my_sportsbook: 'My Sportsbook',
        log_out: 'Log out',
    },
    login: {
        title: 'Sign in to your account',
        username: 'Username',
        password: 'Password',
        sign_in: 'Sign in',
        error: {
            wrong_signatures: 'Wrong username and password combination!',
            something_went_wrong: 'Something went wrong. Please try again later.',
        },
        remember_me: 'Remember me',
        forgot_password: 'Forgot your password?',
    },
    register: {
        title: 'Sign up an account',
        email: 'Email',
        username: 'Username',
        first_name: 'First Name',
        last_name: 'Last Name',
        password: 'Password',
        sign_up: 'Sign up',
        select_currency: 'Select Currency',
        select_language: 'Select Language',
        select_odds_format: 'Select Odds Format',
    },
    footer: {
        all_rights_reserved: 'All rights reserved.',
    }
}
export default en
