import {MutationTree} from "vuex";
import {ApplicationState} from "@/store/application/types";
import applicationMutations from './application-mutations';

export const mutations: MutationTree<ApplicationState> = {
    [applicationMutations.OPEN_LOGIN_MODAL](state, payload: boolean) {
        state.openLoginModal = payload
    },
    [applicationMutations.OPEN_REGISTER_MODAL](state, payload: boolean) {
        state.openRegisterModal = payload
    },
    [applicationMutations.SET_PATH](state, payload: string) {
        state.path = payload
    },
    [applicationMutations.SET_VIEW](state, payload: string) {
        state.view = payload
    },
}