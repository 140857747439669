const br = {
    header: {
        home: 'Início',
        sports: 'Esportes',
        live_betting: 'Apostas ao Vivo',
        today: 'Hoje',
        early: 'Cedo',
        live: 'Viver',
        login: 'Entrar',
        register: 'Registrar',
        balance: 'Saldo:',
    },
    profile_dropdown: {
        my_bets: 'Minhas Apostas',
        my_sportsbook: 'Meu Sportsbook',
        log_out: 'Sair',
    },
    login: {
        title: 'Faça login na sua conta',
        username: 'Nome de usuário',
        password: 'Senha',
        sign_in: 'Entrar',
        error: {
            wrong_signatures: 'Combinação incorreta de nome de usuário e senha!',
            something_went_wrong: 'Algo deu errado. Por favor, tente novamente mais tarde.',
        },
        remember_me: 'Lembrar-me',
        forgot_password: 'Esqueceu sua senha?',
    },
    register: {
        title: 'Crie uma conta',
        email: 'E-mail',
        username: 'Nome de usuário',
        first_name: 'Nome',
        last_name: 'Sobrenome',
        password: 'Senha',
        sign_up: 'Registrar-se',
        select_currency: 'Selecionar Moeda',
        select_language: 'Selecionar Idioma',
        select_odds_format: 'Selecionar Formato de Odds',
    },
    footer: {
        all_rights_reserved: 'Todos os direitos reservados.',
    }
}
export default br
