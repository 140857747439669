<template>
    <footer class="w-full dark:border-gray-800 bg-gray-800 text-white px-6 py-12">
        <div class="container mx-auto md:max-w-2xl">
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div class="col-span-2 mb-6 text-lg font-semibold">
                    IFRAME DEMO
                </div>
                <div>
                    <h3 class="font-bold text-sm">Sports</h3>
                    <ul class="mt-2 space-y-1 text-sm text-gray-400">
                        <li><a href="#" class="hover:underline">Live betting</a></li>
                        <li><a href="#" class="hover:underline">Football</a></li>
                        <li><a href="#" class="hover:underline">Basketball</a></li>
                        <li><a href="#" class="hover:underline">Tennis</a></li>
                        <li><a href="#" class="hover:underline">Volleyball</a></li>
                    </ul>
                </div>
                <div>
                    <h3 class="font-bold text-sm">Legal</h3>
                    <ul class="mt-2 space-y-1 text-sm text-gray-400">
                        <li><a href="#" class="hover:underline">Terms of service</a></li>
                        <li><a href="#" class="hover:underline">Privacy policy</a></li>
                        <li><a href="#" class="hover:underline">License</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: 'MainFooter',
});
</script>
